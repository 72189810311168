'use client';

import {
  Button,
  Container,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { Footer } from '@/components/layout/Footer';
import { getFileS3Url } from '@/helpers/getFileS3Url';
import { Municipality } from '@/types/municipality';

interface MunicipalityHomePageProps {
  entities: Entity[];
  municipality: Municipality;
}

export function MunicipalityHomePage({
  entities,
  municipality,
}: MunicipalityHomePageProps) {
  const name = municipality.name;

  const logoUrl = municipality.logo?.id ? getFileS3Url(municipality.logo) : '';

  return (
    <Container
      maxW="440px"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
      }}
    >
      <VStack
        spacing="6"
        my="8"
        sx={{
          flexGrow: 1,
          width: '100%',
          mt: '15vh',
        }}
      >
        <HStack spacing="5">
          {logoUrl && (
            <Image
              src={logoUrl}
              alt={`Logo of ${name}`}
              width={72}
              height={72}
            />
          )}
          <Heading size="sm" fontWeight={700}>
            {name}
          </Heading>
        </HStack>
        <Text size="xl" color="gray.600" fontWeight="400" textAlign="center">
          Welcome to {name} on MuniCollab
        </Text>
        <Heading size="xs" fontWeight={600}>
          Available Entities
        </Heading>
        <VStack spacing="2.5">
          {entities.map((entity) => (
            <Button
              key={entity.id}
              variant="ghost"
              color="primary.700"
              as={Link}
              rightIcon={<ArrowRightIcon />}
              href={`/${entity.slug}`}
            >
              {entity.name}
            </Button>
          ))}
        </VStack>
      </VStack>
      <Footer />
    </Container>
  );
}
