import { forwardRef, SVGProps } from 'react';

export const ArrowRightIcon = forwardRef<
  SVGSVGElement,
  SVGProps<SVGSVGElement>
>(function ArrowRightIcon(props, ref) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <g id="arrow-right">
        <path
          id="Icon"
          d="M4.16797 10.0013H15.8346M15.8346 10.0013L10.0013 4.16797M15.8346 10.0013L10.0013 15.8346"
          stroke={props.stroke ?? '#175CD3'}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
